<script>
import {Chart} from '@antv/g2';
import constants from '@/components/Constants.vue'

export default {
  name: "LogNginxChart",
  data() {
    return {
      chart: null,
      loading: false,
      ip: "",
      path: "",
      country: "",
      province: "",
      strategy: "pv",
      valid: false,
      model_list: [],
      begin_time: "",
      end_time: "",
      timestampDay: 24 * 60 * 60,
      period: "day",
      strategyList: constants.LogStrategyTypeList
    };
  },
  mounted() {
    let _me = this;
    let t = new Date().getTime();
    _me.begin_time = _me.$common.getDatetimeFromTime(t - (14 * _me.timestampDay * 1000), 'begin');
    _me.end_time = _me.$common.getDatetimeFromTime(t, 'end');
    _me.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;

      if (!_me.validDate()) {
        return
      }

      let params = {
        ip: _me.ip,
        path: _me.path,
        country: _me.country,
        province: _me.province,
        strategy: _me.strategy,
        valid: _me.valid,
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time, 0),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time, 0),
      };
      _me.loading = true;
      _me.$common
          .httpPost("/api/log/admin/nginx/find/analyses", params)
          .then((res) => {
            _me.loading = false;
            if (res.code === "1000000") {
              _me.createChart(res.data);
            } else {
              _me.$message({
                message: res.message || "获取数据失败",
                type: "error",
              });
            }
          })
          .catch((err) => {
            _me.loading = false;
            _me.$message({
              message: err.message || "获取数据失败",
              type: "error",
            });
          });
    },
    createChart(data) {
      let _me = this;

// 初始化图表实例
      if (!_me.chart) {
        _me.chart = new Chart({
          container: 'container',
          theme: 'classic',
          autoFit: true,
          marginLeft: 40,
          marginTop: 40,
          marginRight: 40,
          marginBottom: 40,
        });
      } else {
        _me.chart.clear();
      }

// 声明可视化
      _me.chart
          .line()
          .data(data) // 绑定数据
          .encode('x', 'date') // 编码 x 通道
          .encode('y', 'view') // 编码 y 通道
          .label({
            text: 'view',
            transform: [{type: 'overlapDodgeY'}],
            style: {
              fontSize: 10,
            },
          })

// 渲染可视化
      _me.chart.render();
    },
    downloadExcel() {
      let _me = this;

      if (!_me.validDate()) {
        return
      }

      let params = {
        ip: _me.ip,
        path: _me.path,
        country: _me.country,
        province: _me.province,
        strategy: _me.strategy,
        valid: _me.valid,
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time, 0),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time, 0),
      };

      _me.$confirm("下载图表", "提示", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            instance.closeOnClickModal = false;
            instance.closeOnPressEscape = false;
            instance.showCancelButton = false;
            instance.showClose = false;

            let filenameRegex = /attachment; filename\*=utf-8''(\S*\.xlsx)/;
            await _me.$common
                .httpPost("/api/log/admin/nginx/download/analyses", params, {
                  responseType: "arraybuffer",
                  isAllData: true
                })
                .then(res => {
                  _me.$common.downloadFile(
                      res,
                      res.headers["content-type"],
                      filenameRegex
                  );
                })
                .catch(err => {
                  _me.$message({
                    message: err.message || "获取数据失败",
                    type: "error"
                  });
                })
                .finally(() => {
                  done();
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "确定";
                });
          } else {
            done();
          }
        }
      });
    },
    validDate() {
      let _me = this;

      if (!_me.begin_time || _me.begin_time === "") {
        _me.$message({
          message: "开始时间不正确",
          type: "error",
        });
        return false;
      }

      if (!_me.end_time || _me.end_time === "") {
        _me.$message({
          message: "结束时间不正确",
          type: "error",
        });
        return false;
      }

      let days = new Date(_me.end_time).getTime() - new Date(_me.begin_time).getTime()

      if (days < 0) {
        _me.$message({
          message: "开始时间不能大于结束时间",
          type: "error",
        });
        return false;
      }

      return true
    }
  },
}
</script>

<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="开始时间:">
              <el-date-picker
                  v-model="begin_time"
                  type="datetime"
                  placeholder="选择开始时间"
                  default-time="00:00:00"
                  align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                  v-model="end_time"
                  type="datetime"
                  placeholder="选择结束时间"
                  default-time="23:59:59"
                  align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="来源IP:">
              <el-input
                  v-model="ip"
                  clearable
                  size="medium"
                  placeholder="请输入来源IP"
              ></el-input>
            </el-form-item>
            <el-form-item label="访问路径:">
              <el-input
                  v-model="path"
                  clearable
                  size="medium"
                  placeholder="请输入访问路径"
              ></el-input>
            </el-form-item>
            <el-form-item label="来源国家:">
              <el-input
                  v-model="country"
                  clearable
                  size="medium"
                  placeholder="请输入来源国家"
              ></el-input>
            </el-form-item>
            <el-form-item label="显示策略:">
              <el-select
                  v-model.trim="strategy"
                  clearable
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in strategyList"
                    :key="item.strategy"
                    :label="item.name"
                    :value="item.strategy"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否有效:">
              <el-switch
                  v-model="valid"
                  active-text="是"
                  inactive-text="否"
              >
              </el-switch>
            </el-form-item>
            <el-button
                class="top-button"
                size="medium"
                type="primary"
                round
                @click="getModelList"
            >过滤
            </el-button>
            <el-button
                class="top-button"
                size="medium"
                type="primary"
                round
                @click="downloadExcel"
            >下载
            </el-button>
          </el-form>
        </div>
      </el-header>
      <div class="container" id="container"></div>
    </el-container>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>